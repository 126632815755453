import React from 'react';
import image1 from './assets/melns1.png';
import image2 from "./assets/white_orange.png"

export const Section = () => {
  return (
   <div className="process-items">
      <div
          id={1}
          key={1}
          className='process-item'
      >
        <div
          style={{ maxheight: '600px', borderRadius: '1.5rem' }}
        >
            <a href="https://snapsummary.eu" target='_blank' rel="noreferrer">

          <img
            key={1}
            src={image1}
            width={600}
            height={420}
            alt="SnapSummary logo"
            style={{ width: 'auto', height: "80px", margin: '0 auto' }}
            />
          </a>
        </div>
        <div
          style={{ alignSelf: 'center', color: 'rgba(0, 0, 0, 0.6)' }}
        >
          <a href="https://snapsummary.eu" target='_blank' rel="noreferrer">
            <h3 style={{ marginBottom: '1.5rem', fontSize: '1.5rem', fontWeight: '600', color: 'rgba(0, 0, 0, 1)' }}>
                SnapSummary
            </h3>
          </a>
          <p>Turn property images into captivating stories instantly. The ultimate tool for real estate agents and property owners.</p>
        </div>
      </div>
      <div
        id={2}
        key={2}
        className='process-item'
      >
        <div
          style={{ maxheight: '600px', borderRadius: '1.5rem' }}
        >
           <a href="https://www.teamcheck.co" target='_blank' rel="noreferrer">
          <img
            key={1}
            src={image2}
            width={600}
            height={420}
            alt="TeamCheck logo"
            style={{ width: 'auto', height: "80px", margin: '0 auto' }}
          />
          </a>
        </div>
        <div
          style={{ alignSelf: 'center', color: 'rgba(0, 0, 0, 0.6)' }}
        >
          <a href="https://www.teamcheck.co" target='_blank' rel="noreferrer">
            <h3 style={{ marginBottom: '1.5rem', fontSize: '1.5rem', fontWeight: '600', color: 'rgba(0, 0, 0, 1)' }}>
                TeamCheck
            </h3>
          </a>
          <p>#1 Attendance Management tool – for Coaches, Event Organizers, and Casual Gatherings!</p>
        </div>
      </div>
    </div>
  );
};
