import React from 'react';
import './App.css';
import { Section } from './Section';

const App = () => {
  const year = new Date().getFullYear()
  return (
    <div className="wrapper">
      <div className='first-section'>
        <header className='navbar-wrapper'>
          <a href="https://twitter.com/GirtsOzo" className='navbar-twitter'>
            𝕏 GIRTSOZO
          </a>
          <div className='navbar'>
            <a href="#projects">PROJECTS</a>
            <a href="mailto:info@exgo.one">CONTACT</a>
          </div>
        </header>
        <main className="main-content">
          <div className="text-container">
            <h1>
              Hello, <br/>I'm
            </h1>
            <h1>
              Girts <br/>Ozols
            </h1>
          </div>
        </main>
        <footer className="footer">
          <p style={{paddingBottom: "10px", color: "#ffffff"}}>© {year} EXGO</p>
          <a href="mailto:info@exgo.one">INFO@EXGO.ONE</a>
           <a href="https://twitter.com/GirtsOzo" className='footer-twitter'>
            𝕏 GIRTSOZO
          </a>
        </footer>
      </div>
      <div className='second-section'>
        <h2 id="projects" style={{width: "100%", textAlign: "center"}}>PROJECTS</h2>
        <section className="featured-wrapper">
          <Section />
        </section>
      </div>
    </div>
  );
};

export default App;

